<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="userForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="用户名" prop="name">
          <a-input placeholder="请输入" v-model="info.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password">
          <a-input-password placeholder="请输入" v-model="info.password" autocomplete="new-password"></a-input-password>
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="realName">
          <a-input placeholder="请输入" v-model="info.realName"></a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="telephone">
          <a-input placeholder="请输入" v-model="info.telephone"></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户角色" prop="roles">
          <a-transfer
            showSearch
            :dataSource="rolesAll"
            :filterOption="filterOption"
            :targetKeys="info.roles"
            @change="handleTransferChange"
            :render="(item) => item.title"
            :listStyle="{ width: '228px', height: '300px' }"
          ></a-transfer>
        </a-form-model-item>

        <a-form-model-item label="用户状态">
          <a-switch v-model="info.status" checkedChildren="启用" unCheckedChildren="禁用" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 6 }">
          <pxkeji-btn name="确认" type="primary" @click="handleSubmit" />
          <pxkeji-btn name="取消" type="default" @click="cancelClick" class="cancelBtn" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this
export default {
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '' && this.infos.id == 0) {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      isloading: false,
      loadingTip: '加载中...',
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      info: {
        password: '',
        status: true,
        roles: [],
      },
      rules: {
        name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'change' }],
        telephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ],
        roles: [
          { required: true, message: '请输入选择角色', trigger: 'change' },
        ],
      },
      isVal: false,
      rolesAll: [],
    }
  },
  mounted() {
    _this = this
    _this.getRolesAll()
  },
  methods: {
    cancelClick() {
      _this.$emit('callbackMethod')
    },
    handleSubmit() {
      //提交
      _this.$refs.userForm.validate((valid) => {
        console.log(valid)
        if (valid) {
          let formData = { ..._this.info }
          delete formData.showName
          console.log(formData)
          formData.status = _this.$commen.changeBooleanInt(formData.status)
          formData.roleIds = _this.info.roles.join(',')
          delete formData.roles
          _this.isloading = true
          _this.loadingTip = '保存中...'
          if (_this.infos.id) {
            //修改
            _this.$api.user
              .update(formData)
              .then((res) => {
                console.log(res)
                if (res.errorCode == _this.$msg.responseCode.success) {
                  console.log(111)
                  _this.$message.success(
                    res.errorMsg ? res.errorMsg : '保存成功'
                  )
                  _this.$emit('callbackMethod')
                } else {
                  _this.$message.error(res.errorMsg)
                }
                _this.isloading = false
              })
              .catch(() => {
                _this.isloading = false
              })
          } else {
            _this.$api.user
              .save(formData)
              .then((res) => {
                if (res.errorCode == _this.$msg.responseCode.success) {
                  _this.$message.success(
                    res.errorMsg ? res.errorMsg : '保存成功'
                  )
                  _this.$emit('callbackMethod')
                } else {
                  _this.$message.error(res.errorMsg)
                }
                _this.isloading = false
              })
              .catch(() => {
                _this.isloading = false
              })
          }
        } else {
          return false
        }
      })
    },
    filterOption(inputValue, option) {
      return option.title.indexOf(inputValue) > -1
    },
    handleTransferChange(targetKeys) {
      _this.info.roles = targetKeys
    },
    getRolesAll() {
      _this.isloading = true
      _this.$api.role
        .getForAdmin()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            for (let i = 0; i < res.data.length; i++) {
              _this.rolesAll.push({
                key: res.data[i].id + '',
                title: res.data[i].name,
              })
            }
            if (_this.infos.id) {
              _this.isVal = false
              _this.getUserInfo()
            } else {
              _this.isVal = true
              _this.isloading = false
            }
          } else {
            _this.isloading = false
            _this.$message.error(res.errorMsg)
          }
        })
        .catch(() => {
          _this.isloading = false
        })
    },
    getUserInfo() {
      _this.isloading = true
      _this.$api.user
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            res.data.password = ''
            _this.info = res.data
            console.log(_this.info)
            _this.info.status = _this.$commen.changeIntBoolean(res.data.status)
            _this.info.roles = res.data.roles
              ? res.data.roles.map((item) => item.id + '')
              : []
          } else {
            _this.$message.error(res.errorMsg)
          }
          _this.isloading = false
        })
        .catch(() => {
          _this.isloading = false
        })
    },
  },
}
</script>