<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="用户信息">
              <a-input
                placeholder="请输入用户名或手机号"
                v-model="search.name"
              />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="状态">
              <a-select v-model="search.status" placeholder="请选择" allowClear>
                <a-select-option value>全部</a-select-option>
                <a-select-option value="0">禁用</a-select-option>
                <a-select-option value="1">启用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="添加"
          class="pxkeji_basic_btn"
          @click="showInfo({ id: 0 })"
          type="primary"
          :roles="['系统用户-添加']"
        />
        <pxkeji-btn
          name="批量启用"
          :disabled="selectRows.length == 0"
          @click="batchEnable()"
          class="pxkeji_basic_btn"
          :roles="['系统用户-启用禁用']"
        />
        <pxkeji-btn
          name="批量禁用"
          :disabled="selectRows.length == 0"
          @click="batchDisEnable()"
          class="pxkeji_basic_btn"
          :roles="['系统用户-启用禁用']"
        />
        <span style="margin-left: 8px">
          <template v-if="selectRows.length > 0">
            {{ `已选择 ${selectRows.length} 项` }}
            <a-button type="link" @click="cancelSelect"> 取消选择 </a-button>
          </template>
        </span>
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        bordered
        size="small"
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <span slot="roles" slot-scope="roles">
          <a-tag v-for="info in roles" :key="info.id" color="green">
            {{ info.name }}
          </a-tag>
        </span>
        <span slot="status" slot-scope="status">
          <a-tag v-if="status" color="green">启用</a-tag>
          <a-tag v-else color="red">禁用</a-tag>
        </span>
        <span slot="action" slot-scope="info">
          <pxkeji-btn
            name="编辑"
            @click="showInfo(info)"
            size="small"
            type="primary"
            :roles="['系统用户-编辑']"
          />
          <a-popconfirm
            v-if="info.status == 1"
            title="是否确认禁用此用户？"
            @confirm="handleProhibit(info.id, 0)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="禁用"
              size="small"
              type="danger"
              :roles="['系统用户-启用禁用']"
            />
          </a-popconfirm>
          <a-popconfirm
            v-else
            title="是否确认启用此用户？"
            @confirm="handleProhibit(info.id, 1)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="启用"
              size="small"
              :roles="['系统用户-启用禁用']"
            />
          </a-popconfirm>
          <a-popconfirm
            title="是否确认将此用户的密码重置为初始密码(123456)？"
            @confirm="resetpaw(info.id)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="重置密码"
              size="small"
              :roles="['系统用户-重置用户密码']"
            />
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="900px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <userInfo :infos="addedit.info" @callbackMethod="save" />
    </a-modal>
  </div>
</template>
<script>
import userInfo from "./userInfo.vue";

const columns = [
  {
    dataIndex: "name",
    align: "left",
    title: "用户名",
  },
  {
    dataIndex: "roles",
    align: "center",
    title: "角色",
    scopedSlots: { customRender: "roles" },
  },
  {
    dataIndex: "realName",
    align: "center",
    title: "姓名",
  },
  {
    dataIndex: "telephone",
    align: "center",
    title: "手机号",
  },
  {
    dataIndex: "addTime",
    align: "center",
    width: 160,
    title: "创建时间",
  },
  {
    dataIndex: "status",
    align: "center",
    title: "状态",
    width: 120,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "userlist",
  data() {
    return {
      rolelist: [],
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 292,
      },
      //查询条件
      search: {
        name: "",
        status: "",
      },
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
      selectRows: [], //选中项编号
      isloading: false,
    };
  },
  components: {
    userInfo: userInfo,
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 292;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取用户列表
      _this.isloading = true;
      _this.cancelSelect();
      _this.$api.user
        .pageListAdmin(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.name,
          _this.search.status
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      //table 多选
      _this.selectRows = selectedRowKeys;
    },
    cancelSelect() {
      //清空选中项
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(info) {
      //打开弹出框
      _this.addedit.visible = true;
      _this.addedit.title = info.id ? "编辑" : "新增";
      _this.addedit.info = info;
    },
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
    /* 删除 */
    delInfo(id) {
      _this.isloading = true;
      _this.$api.user
        .del(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.isloading = false;
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /*批量启用 */
    batchEnable() {
      _this.$confirm({
        title: "提示",
        content: `您确定要启用所选的用户吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.handleProhibit(_this.selectRows.join(","), 1);
        },
        onCancel() {},
      });
    },
    /*批量禁用 */
    batchDisEnable() {
      _this.$confirm({
        title: "提示",
        content: `您确定要禁用所选的用户吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.handleProhibit(_this.selectRows.join(","), 0);
        },
        onCancel() {},
      });
    },
    /*禁用启用 */
    handleProhibit(ids, status) {
      _this.isloading = true;
      _this.$api.user
        .prohibit(ids, status)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.isloading = false;
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    resetpaw(id) {
      _this.isloading = true;
      _this.$api.user
        .resetPassword(id, "123456")
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

